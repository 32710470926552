import pdfMake from 'pdfmake/build/pdfmake'
import customVfs from './customVfs'

// Funcion para construir el cuerpo de la tabla
export function buildTableBody(
   data,
   columns,
   showHeaders,
   headers,
   customRemove
) {
   var body = []
   // Insertando headers
   if (showHeaders) {
      body.push(headers)
   }

   // Insertando filas desde data externa
   data.forEach(function (row) {
      var dataRow = []
      var i = 0

      columns.forEach(function (column) {
         var tempRemove
         var currentValue = Object.byString(row, column)

         // Agregando formato numerico internacional
         if (headers[i].numFormat && !isNaN(currentValue)) {
            currentValue = new Intl.NumberFormat('en-US', {
               minimumFractionDigits: 2
            }).format(currentValue)
         }

         // Removiendo custom string si es necesario
         tempRemove = currentValue == customRemove ? '' : currentValue

         if (headers[i].mlMultiplier) {
            var delimiter =
               headers[i].delimiter == undefined ? 0.1 : headers[i].delimiter
            dataRow.push({
               text: tempRemove,
               alignment: headers[i].alignmentChild,
               margin: [row[headers[i].mlMultiplier] / delimiter, 0, 0, 0]
            })
         } else {
            dataRow.push({
               text: tempRemove,
               alignment: headers[i].alignmentChild
            })
         }
         i++
      })
      body.push(dataRow)
   })
   return body
}

// Funcion para construir la tabla
export function table(
   data,
   columns,
   witdhsDef,
   showHeaders,
   headers,
   layoutDef,
   customRemove,
   marginDef = [0, 0, 0, 0]
) {
   return {
      table: {
         dontBreakRows: true,
         headerRows: showHeaders ? 1 : 0,
         widths: witdhsDef,
         body: buildTableBody(data, columns, showHeaders, headers, customRemove)
      },
      layout: layoutDef,
      margin: marginDef
   }
}

// Obtener valor de objeto segun propiedad
Object.byString = function (o, s) {
   s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
   s = s.replace(/^\./, '') // strip a leading dot
   var a = s.split('.')
   for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i]
      if (k in o) {
         o = o[k]
      } else {
         return
      }
   }
   return o
}

// Genera y exporta reporte en un archivo PDF.
export function generarPDF(mode, dd, filename) {
   pdfMake.vfs = customVfs.vfs

   pdfMake.fonts = {
      // Default font should still be available
      Roboto: {
         normal: 'Roboto-Regular.ttf',
         bold: 'Roboto-Medium.ttf',
         italics: 'Roboto-Italic.ttf',
         bolditalics: 'Roboto-MediumItalic.ttf'
      },
      // Fuente de Times New Roman agregada
      TimesNewRoman: {
         normal: 'Times-New-Roman-Regular.ttf',
         bold: 'Times-New-Roman-Bold.ttf',
         italics: 'Times-New-Roman-Italics.ttf',
         bolditalics: 'Times-New-Roman-BoldItalics.ttf'
      },
      // Fuente codigo de barra
      CodigoDeBarra: {
         normal: 'IDAutomationC128M.ttf'
      }
   }

   if (mode == 'open') {
      pdfMake.createPdf(dd).open()
   } else if (mode == 'save') {
      pdfMake.createPdf(dd).download(filename)
   }
}

export var dateOptions = {
   year: '2-digit',
   month: '2-digit',
   day: '2-digit',
   hour: '2-digit',
   minute: '2-digit',
   hour12: 'true'
}
